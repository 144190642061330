<template>

    <div id="map" 
         class="w-full h-screen rounded-lg"
         v-bind:style="{opacity: map_opacity}">
    </div>
    <div id="imagery-menu" 
         class="absolute p-2 top-0 left-0 right-0 bg-transparent 
              text-white text-center">
        <input id="nj-2020-1ft-layer" 
               type="radio" 
               name="rtoggle" 
               value="nj-2020-1ft" 
               checked="checked"
               class="mr-1"
               @click="switchImageryLayer">
        <label for="nj-2020-1ft-layer"
               class="mr-4">
            2020
        </label>
        <input id="nj-2020-ir-1ft-layer" 
               type="radio" 
               name="rtoggle" 
               value="nj-2020-ir-1ft" 
               class="mr-1"
               @click="switchImageryLayer">
        <label for="nj-2020-ir-1ft-layer"
               class="mr-4">
            2020 IR
        </label>
        <input id="nj-2015-1ft-layer" 
               type="radio" 
               name="rtoggle" 
               value="nj-2015-1ft" 
               class="mr-1"
               @click="switchImageryLayer">
        <label for="nj-2015-1ft-layer"
               class="mr-4">
            2015
        </label>
        <input id="nj-2015-ir-1ft-layer" 
               type="radio" 
               name="rtoggle" 
               value="nj-2015-ir-1ft" 
               class="mr-1"
               @click="switchImageryLayer">
        <label for="nj-2015-ir-1ft-layer"
               class="mr-4">
            2015 IR
        </label>
        <input id="maptiler-layer" 
               type="radio" 
               name="rtoggle" 
               value="maptiler" 
               class="mr-1"
               @click="switchImageryLayer">
        <label for="maptiler-layer"
               class="mr-4">
               Maptiler
        </label>
        <input id="osm-layer" 
               type="radio" 
               name="rtoggle" 
               value="osm" 
               class="mr-1"
               @click="switchImageryLayer">
        <label for="osm-layer"
               class="mr-4">
            OSM
        </label>
    </div>
    <div id="overlay-menu" 
         class="absolute p-2 top-8 left-0 right-0 bg-transparent 
              text-white text-center"
         v-show="selected_farm_id>0 && available_features_checked">
        <input id="lulc-2020-layer" 
               type="checkbox" 
               name="rtoggle" 
               value="lulc-2020"
               :disabled="on_layer!='' && on_layer!='lulc-2020-layer'"
               class="mr-1"
               @click="toggleFeatureOverlay">
        <label for="lulc-2020-layer"
               class="mr-4">
            LULC 2020
        </label>
        <input id="features-2013-layer" 
               type="checkbox" 
               name="rtoggle" 
               value="features-2013" 
               :disabled="on_layer!='' && on_layer!='features-2013-layer'"
               class="mr-1"
               v-show="available_feature_layers.includes('features_2013')"
               @click="toggleFeatureOverlay">
        <label for="features-2013-layer"
               class="mr-4"
               v-show="available_feature_layers.includes('features_2013')">
            2013 Features
        </label>
        <input id="features-2021-layer" 
               type="checkbox" 
               name="rtoggle" 
               value="features-2021"
               :disabled="on_layer!='' && on_layer!='features-2021-layer'"
               class="mr-1"
               v-show="available_feature_layers.includes('features_2021')"
               @click="toggleFeatureOverlay">
        <label for="features-2021-layer"
               class="mr-4"
               v-show="available_feature_layers.includes('features_2021')">
            2021 Features
        </label>
        <input id="features-2021-update-layer" 
               type="checkbox" 
               name="rtoggle" 
               value="features-2021-update"
               :disabled="on_layer!='' && on_layer!='features-2021-update-layer'"
               class="mr-1"
               v-show="available_feature_layers.includes(
                                                    'features_2021_update')"
               @click="toggleFeatureOverlay">
        <label for="features-2021-update-layer"
               class="mr-4"
               v-show="available_feature_layers.includes('features_2021_update')">
            2021 Features (Update)
        </label>
        <input id="impervious-surface-2015-layer" 
               type="checkbox" 
               name="rtoggle" 
               value="impervious-surface-2015"
               :disabled="on_layer!='' && on_layer!='impervious-surface-2015-layer'"
               class="mr-1"
               @click="toggleFeatureOverlay">
        <label for="impervious-surface-2015-layer"
               class="mr-4">
            Impervious Surface 2015
        </label>
    </div>
    <classifier 
        class="top-0 left-0"
        :map="map"
        :show_classifier="show_classifier"
        :current_feature="current_feature"
        :reclassify_mode="reclassify_mode"
        v-show="show_classifier"
        @selected_code="updateFeatureCode"
        @cancel_classification="cancelClassification"
        @exit_reclassify_mode="exitReclassifyMode"
        @enter_edit_mode="openEditMode"
        @reclassify_feature="reclassifyFeature"
        @delete_feature="deleteFeature"
        @codes="updateCodes">
    </classifier>
    <feature-retriever 
        class="top-0 left-0"
        :map="map"
        :feature="clicked_feature"
        :userid="userid"
        :reclassify_mode="reclassify_mode"
        :codes="codes"
        v-show="show_feature_retriever"
        @features_added="closeFeatureRetriever">
    </feature-retriever>

</template>

<script>

    import axios from 'axios';
    import centroid from '@turf/centroid'
    import Classifier from './Classifier.vue'
    import FeatureRetriever from './FeatureRetriever.vue'
    import mapboxgl from 'mapbox-gl';
    import MapboxDraw from "@mapbox/mapbox-gl-draw";
    import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
    import {
        SnapPolygonMode,
        SnapModeDrawStyles,
    } from "mapbox-gl-draw-snap-mode";
    import {
        CircleMode,
        DragCircleMode,
        DirectMode,
        SimpleSelectMode
    } from 'mapbox-gl-draw-circle';
    import 'mapbox-gl/dist/mapbox-gl.css';

    export default {
               
        name: 'Map',

        components:{
            "classifier": Classifier,
            "feature-retriever": FeatureRetriever
        },

        props:['selected_farm', 'feature_update', 'userid', 'clear_sources', 
               'reopen_farm', 'action_completed'], 
        
        emits: ['map_created', 'new_feature', 'reclassified_feature',
                'edited_feature', 'delete_feature', 'disable',
                'refresh_delineation', 'codes'],

        data(){
            return{
                style: 'Mapbox Light',
                map: null,
                draw: null,
                circle_id: null,
                map_opacity: 1,
                selected_farm_id: 0,
                farm_status: 0,
                show_feature_retriever: false,
                clicked_feature: null,
                show_classifier: false,
                reclassify_mode: false,
                edit_mode: false,
                disabled: false,
                on_layer: '',
                draw_mode: true,
                codes: [null],
                feature_code: 0,
                current_feature: null,
                controls: [
                    [new mapboxgl.NavigationControl(), 'top-right'],
                    // [new mapboxgl.AttributionControl()],
                    [new mapboxgl.ScaleControl({position: 'bottom-left',
                                                unit: 'imperial'})],
                    [new mapboxgl.FullscreenControl(
                            {container: document.querySelector('body')})]
                ],
                view: {
                    latitude: 40.2188,
                    longitude: -74.5656,
                    zoom: 9,
                    bearing: 0,
                    pitch: 0
                },
                imagery_layers: ['maptiler-layer', 'nj-2020-1ft-layer', 
                                 'nj-2020-ir-1ft-layer', 'nj-2015-1ft-layer', 
                                 'nj-2015-ir-1ft-layer', 'osm-layer'],
                feature_layers: ['lulc-2020-layer', 'features-2013-layer', 
                                 'features-2021-layer', 
                                 'features-2013-update-layer', 
                                 'impervious-surface-2015-layer'],
                available_feature_layers:[],
                available_features_checked: false,
                // first_label_layer_Id: null,
                
                paint: [
                    "case", 
                        ['==', ['get', "code"], 0], "#a9a9a9",
                        ['==', ['get', "code"], 2100], "#800000",
                        ['==', ['get', "code"], 7000], "#800000",
                        ['==', ['get', "code"], 2500], "#483d8b",
                        ['==', ['get', "code"], 2200], "#008000",
                        ['==', ['get', "code"], 6100], "#008080",
                        ['==', ['get', "code"], 6200], "#000080",
                        ['==', ['get', "code"], 5100], "#9acd32",
                        ['==', ['get', "code"], 5200], "#ff0000",
                        ['==', ['get', "code"], 3100], "#ffff00",
                        ['==', ['get', "code"], 3200], "#7e5df5",
                        ['==', ['get', "code"], 7410], "#40e0d0",
                        ['==', ['get', "code"], 7300], "#8a2be2",
                        ['==', ['get', "code"], 4100], "#00ff7f",
                        ['==', ['get', "code"], 4110], "#00bfff",
                        ['==', ['get', "code"], 4300], "#0000ff",
                        ['==', ['get', "code"], 2210], "#ff00ff",
                        ['==', ['get', "code"], 1000], "#7cfc00",
                        ['==', ['get', "code"], 8000], "#eee8aa",
                        ['==', ['get', "code"], 2230], "#ff1493",
                        ['==', ['get', "code"], 2300], "#ee82ee",
                        ['==', ['get', "code"], 2300], "#ee82ee",
                        ['==', ['get', "code"], 4200], "#5d5df0",
                        ['==', ['get', "code"], 3300], "#eded3e",
                        ['==', ['get', "code"], 5110], "#82989e",
                        ['==', ['get', "code"], 6300], "#0db2db",
                        ['==', ['get', "code"], 7100], "#f5e022",
                        ['==', ['get', "code"], 7200], "#f5bd22",
                        ['==', ['get', "code"], 7400], "#52f2e2",
                        ['==', ['get', "code"], 8100], "#DB5079",
                        ['==', ['get', "code"], 4000], "#28c979",
                        ['==', ['get', "code"], 5000], "#a993bf",
                        ['==', ['get', "code"], 2620], "#127558",
                        ['==', ['get', "code"], 2610], "#3b967b",
                        ['==', ['get', "code"], 2110], "#5df0d2",
                        ['==', ['get', "code"], 2000], "#3737e6",
                        ['==', ['get', "code"], 3000], "#c98912",
                        ['==', ['get', "code"], 6000], "#5bebeb",
                        ['==', ['get', "code"], 3400], "#cef522",
                        ['==', ['get', "code"], 6310], "#03a374",
                   '#ffffff'
                ],
                
                draw_layers: [
                    'gl-draw-polygon-fill-active.cold',
                    'gl-draw-polygon-fill-active.hot',
                    'gl-draw-polygon-fill-inactive.cold',
                    'gl-draw-polygon-fill-inactive.hot',
                    'gl-draw-polygon-fill-static.cold',
                    'gl-draw-polygon-fill-static.hot',
                ],

                blankStyle: {
                    version: 8,
                    name: "Blank",
                    center: [0, 0],
                    zoom: 0,
                    sources: {},
                    sprite: "file://roblabs.com/sprite",
                    glyphs: "file://roblabs.com/fonts/mapbox/{fontstack}/{range}.pbf",
                    layers: [],
                    id: "blank"
                }
            }
        },

        watch:{
            selected_farm: function(){
                if (!this.selected_farm){
                    this.reset();
                    return;
                }
                this.parseSelectedFarm();

                if(this.selected_farm_id > 0){
                    if(this.farm_status < 3){
                        this.enableDraw(true);
                    }
                    this.getAvailableFeatureLayers();
                    this.available_features_checked = false;
                }else{
                    this.reset();
                }
            },

            feature_update: function(){
                this.show_classifier = false;
            },

            clear_sources: function(){
                this.clearSources();
            },

            reopen_farm: function(){
                this.enableDraw();
                this.farm_status = 2;
            },

            action_completed: function(){
                this.$emit('disable', false);
            }
        },

        created(){
            let token = ''

            token = 'pk.eyJ1Ijoicm93YW4tZ2VvbGFiIiwiYSI6ImNrejFqbm4zYTF';
            token += 'rbmsybnFrbTVudHV1bWcifQ.wXHi_mHG8gyoxTq4XM_nFA'

            mapboxgl.accessToken = token;
        },

        methods:{

            parseSelectedFarm(){
                this.selected_farm_id = this.selected_farm.idn_proper;
                this.farm_status = this.selected_farm.status;
            },

            createMap(){
  
                this.map = new mapboxgl.Map({
                    container:  'map',
                    style: 'mapbox://styles/mapbox/light-v10',
                    antialias: true,
                    center: [this.view.longitude,this.view.latitude],
                    zoom: this.view.zoom,
                    bearing: this.view.bearing,
                    pitch: this.view.pitch,
                    attributionControl: false,
                    ScaleControl: false,
                    preserveDrawingBuffer: true
                });
                this.addControls();
                this.addDrawHandlers();
                this.map
                .on('load', () => {
                    this.adjustMapboxLayerOpacity();
                    this.addIMagerySources();
                    this.addNj2020Layer();
                    // this.createPopupLabeler();
                })

                this.$emit('map_created', {'map': this.map, 
                                           'view': this.view,
                                           'draw': this.draw});
            },

            addControls(){
                for (const control of this.controls) {
                    this.map.addControl(control[0], control[1]);
                }

                this.draw = new MapboxDraw({

                    modes: {
                        ...MapboxDraw.modes,
                        draw_polygon: SnapPolygonMode,
                        draw_circle  : CircleMode,
                        drag_circle  : DragCircleMode,
                        direct_select: DirectMode,
                        simple_select: SimpleSelectMode
                    },
                    controls:{'point': false, 'line_string': false, 
                        "draw_circle":true, 'combine_features': false, 
                        'uncombine_features': false, 'trash': false},
                    userProperties: true,
                    styles: [...SnapModeDrawStyles],
                    snap: false,
                    snapOptions: {
                        snapPx: 8, // defaults to 15
                        snapVertexPriorityDistance: 0.0000000025, // defaults to 1.25
                    },
                    guides: false,
                });
                this.addDrawMenu();
                this.updateDrawStyles();

                class opacityControl { 
            
                    onAdd(map) {
                        this.map = map;
                        this.container = document.createElement('div');
                        this.container.className = 'mapboxgl-ctrl';
                        this.container.classList.add('h-auto');
                        this.container.classList.add('w-20');
                        this.container.classList.add('z-50');
                        this.container.classList.add('pt-1');
                        this.container.classList.add('bg-transparent');
                        this.container.classList.add('align-middle');
                        this.slider =  document.createElement('input');
                        this.slider.id = 'opacifier';
                        this.slider.type = 'range';
                        this.slider.min = '0';
                        this.slider.max = '100';
                        this.slider.step = '1';
                        this.slider.classList.add('w-full');
                        this.slider.classList.add('h-4');
                        this.slider.classList.add('form-range');
                        this.slider.classList.add('opacity-80');
                        this.container.appendChild(this.slider);

                        return this.container;
                    }

                    onRemove() {
                        this.container.parentNode.removeChild(this.container);
                        this.map = undefined;
                    }
                }

                const opacifier = new opacityControl();
                this.map.addControl(opacifier, 'bottom-right');
            },

            addDrawMenu(){
                this.map.addControl(this.draw, 'top-left');
                const menu = document.getElementsByClassName(
                                                'mapbox-gl-draw_polygon')[0];  
                const elem = document.createElement('button');
                elem.classList.add("mapbox-gl-draw_ctrl-draw-btn");
                elem.setAttribute('title', 'Circle tool');
                elem.innerHTML = `<svg class="h-full w-full">
                <circle cx="15" cy="15" r="6" stroke="black" stroke-width="2"
                fill="white"/></svg>`;
                menu.parentNode.insertBefore(elem, menu);
                this.enableDraw(false);
                elem.addEventListener("click", () => {
                    this.draw.changeMode('draw_circle', 
                                        { initialRadiusInKm: 0.004572 });
                });
            },

            addDrawHandlers(){

                this.map.on('draw.create',  (e) => {
                  
                    this.current_feature = e.features[0];
  
                    if(e.features[0].properties.isCircle){
                        this.circle_id = e.features[0].id;
                        return;
                    }
                    this.show_classifier = true;
                        // this.disabled = true;
                        // this.$emit('disable', this.disabled);
                });

                this.map.on('draw.update', (e)=>{
                    if(this.edit_mode && 
                       this.current_feature.properties.featureid == 
                       e.features[0].properties.featureid){
                        return;
                    }
                    this.current_feature = e.features[0];

                    if(e.features[0].id == this.circle_id){
                        this.circle_id = null;
                    }
                    this.show_classifier = true;
                    this.disabled = true;
                    this.$emit('disable', this.disabled);
                });

                this.map.on('draw.delete', (e)=>{
                    let feature = e.features[0];
                    this.$emit('delete_feature', feature);
                });

                this.map.on('draw.selectionchange', (e) => {
                   
                    if(e.features.length == 0){
                        if(this.edit_mode){
                            if(this.current_feature){
                                this.$emit('edited_feature',
                                    this.draw.get(this.current_feature.id));
                            }
                            this.current_feature = null;
                            this.closeEditMode();
                            return;
                        }
                        this.reclassify_mode = false;
                        this.draw_mode = true;
                        this.disabled = false;
                        this.$emit('disable', this.disabled);
                        return;
                    }else if(this.reclassify_mode){
                        this.reclassify_mode = false;
                        this.show_classifier = false;
                        this.draw_mode = true;
                        this.disabled = false;
                        this.$emit('disable', this.disabled);
                    }else if(this.edit_mode){
                        return;
                    }else if(this.draw_mode){
                        if(Object.prototype.hasOwnProperty.call(
                            e.features[0].properties,'isCircle')){
                                return;
                        }
                        if(Object.prototype.hasOwnProperty.call(
                            e.features[0].properties,'featureid')){
                            this.current_feature = e.features[0];
                            this.reclassify_mode = true;
                            this.show_classifier = true;
                            this.draw_mode = false;
                            this.disabled = true;
                            this.$emit('disable', this.disabled);
                        }else{
                            this.current_feature = e.features[0];
                            this.show_classifier = true;
                            this.disabled = true;
                            this.$emit('disable', this.disabled);
                        }
                    }else{
                        return;
                    }
                });
            },

            enableDraw(enable=true){
                const el = document.getElementsByClassName(
                                                'mapboxgl-ctrl-top-left')[0];
                
                if(enable){
                    el.classList.remove('hidden');
                }else{
                    el.classList.add('hidden');
                }
                
            },

            tweakDrawMenuStyle(){
                let el = document
                            .getElementsByClassName('mapboxgl-ctrl-top-left')[0]

                el.classList.add("mt-20");
            },
            
            updateDrawStyles(val){
                const opacity = val?val:.5

                this.draw.options.styles[0].paint['fill-opacity'] = opacity;

                this.draw.options.styles[0].paint['fill-color'] = [
                    "case", 
                        ['==', ['get', "user_code"], 0], "#a9a9a9",
                        ['==', ['get', "user_code"], 2100], "#800000",
                        ['==', ['get', "user_code"], 7000], "#800000",
                        ['==', ['get', "user_code"], 2500], "#483d8b",
                        ['==', ['get', "user_code"], 2200], "#008000",
                        ['==', ['get', "user_code"], 6100], "#008080",
                        ['==', ['get', "user_code"], 6200], "#000080",
                        ['==', ['get', "user_code"], 5100], "#9acd32",
                        ['==', ['get', "user_code"], 5200], "#ff0000",
                        ['==', ['get', "user_code"], 3100], "#ffff00",
                        ['==', ['get', "user_code"], 3200], "#7e5df5",
                        ['==', ['get', "user_code"], 7410], "#40e0d0",
                        ['==', ['get', "user_code"], 7300], "#8a2be2",
                        ['==', ['get', "user_code"], 4100], "#00ff7f",
                        ['==', ['get', "user_code"], 4110], "#00bfff",
                        ['==', ['get', "user_code"], 4300], "#0000ff",
                        ['==', ['get', "user_code"], 2210], "#ff00ff",
                        ['==', ['get', "user_code"], 1000], "#7cfc00",
                        ['==', ['get', "user_code"], 8000], "#eee8aa",
                        ['==', ['get', "user_code"], 2230], "#ff1493",
                        ['==', ['get', "user_code"], 2300], "#ee82ee",
                        ['==', ['get', "user_code"], 2300], "#ee82ee",
                        ['==', ['get', "user_code"], 4200], "#5d5df0",
                        ['==', ['get', "user_code"], 3300], "#eded3e",
                        ['==', ['get', "user_code"], 5110], "#82989e",
                        ['==', ['get', "user_code"], 6300], "#0db2db",
                        ['==', ['get', "user_code"], 7100], "#f5e022",
                        ['==', ['get', "user_code"], 7200], "#f5bd22",
                        ['==', ['get', "user_code"], 7400], "#52f2e2",
                        ['==', ['get', "user_code"], 8100], "#DB5079",
                        ['==', ['get', "user_code"], 4000], "#28c979",
                        ['==', ['get', "user_code"], 5000], "#a993bf",
                        ['==', ['get', "user_code"], 2620], "#127558",
                        ['==', ['get', "user_code"], 2610], "#3b967b",
                        ['==', ['get', "user_code"], 2110], "#5df0d2",
                        ['==', ['get', "user_code"], 2000], "#3737e6",
                        ['==', ['get', "user_code"], 3000], "#c98912",
                        ['==', ['get', "user_code"], 6000], "#5bebeb",
                        ['==', ['get', "user_code"], 3400], "#cef522",
                        ['==', ['get', "user_code"], 6310], "#03a374",
                    '#ffffff'
                ];
            },

            addOpacityListener(){
                const slider = document.getElementById('opacifier');

                slider.addEventListener('input', (e) => {
                    this.draw_layers.concat(this.feature_layers).forEach(
                        layer => {
                            if(this.map.getLayer(layer)){
                                this.map.setPaintProperty(
                                    layer,
                                    'fill-opacity',
                                    parseInt(e.target.value, 10) / 100
                                )
                            }
                    });
                })
            },

            updateFeatureCode(code){
                this.feature_code = code;
                this.$emit('new_feature', {'feature': this.current_feature, 
                                           'code': code,
                                           'featureid': 0});
            },

            cancelClassification(){
                this.show_classifier = false;
                if( Object.keys(this.current_feature.properties).length === 0){
                    this.draw.delete(this.current_feature.id);
                }
                this.disabled = false;
                this.$emit('disable', this.disabled);
            },

            reclassifyFeature(reclassified_feature){
                this.$emit('reclassified_feature', reclassified_feature);
                this.exitReclassifyMode();
            },

            deleteFeature(feature){
                this.$emit('delete_feature', feature);
                this.exitReclassifyMode();
            },

            exitReclassifyMode(){
                this.show_classifier = false;
                this.reclassify_mode = false;
                this.draw_mode = true;
                this.disabled = false;
                this.draw.changeMode('simple_select', { 
                    featureIds: []});  //unselects selected feature
                this.$emit('disable', this.disabled);
            },

            openEditMode(){
                this.show_classifier = false;
                this.reclassify_mode = false;
                this.edit_mode = true;
                this.draw_mode = false;
            },

            closeEditMode(){
                this.edit_mode = false;
                this.draw_mode = true;
                this.disabled = false;
                this.$emit('disable', this.disabled);
            },

            reset(){
                this.enableDraw(false);
                this.removeFeatureSources();
                this.available_feature_layers = [];
                this.selected_farm_id = 0;
                this.farm_status = 0;
            },

            adjustMapboxLayerOpacity(){
                const layers = this.map.getStyle().layers;
                const types = ['fill', 'line', 'symbol', 'background'];

                layers.forEach(element => {
                    if(types.includes(element.type)){
                        if(element.id.includes('gl-draw')){
                            return;
                        }
                        const type = element.type == 'symbol'?
                                                     'text':element.type
                        this.map.setPaintProperty(
                             element.id,
                             type + '-opacity',
                            .0
                        );

                    }
                });
            },

            createPopupLabeler(){

                this.popup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false
                });

                this.draw_layers.concat(this.feature_layers).forEach(layer => {
                    this.map.on('mouseenter', layer, (e) => {
                        if(this.draw.getMode().includes('draw')){
                            return;
                        }
                        this.map.getCanvas().style.cursor = 'pointer';
                        
                        const coordinates = centroid(e.features[0])
                                            .geometry.coordinates;
                        
                        let description = '';
                        
                        if(Object.prototype.hasOwnProperty.call(
                                        e.features[0].properties,'code')){
                            description = 
                                this.codes[e.features[0].properties.code]
                                                                .description;
                        }else if(Object.prototype.hasOwnProperty.call(
                                        e.features[0].properties,'user_code')){
                            description = 
                                this.codes[e.features[0].properties.user_code]
                                                                .description;
                        }
                        
                        while (Math.abs(e.lngLat.lng-coordinates[0])>180) {
                        coordinates[0] += e.lngLat.lng>coordinates[0]?360:-360;
                        }
                        
                        this.popup.setLngLat(coordinates).setHTML(description)
                                                         .addTo( this.map);
                    });
                        
                    this.map.on('mouseleave', layer, () => {
                        this.map.getCanvas().style.cursor = '';
                        this.popup.remove();
                    });
                });
            },

            addIMagerySources(){

                this.map.addSource('maptiler',{
                    'type': 'raster',
                    'tiles': [
                        'https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=qzs6NSideddtGPx79MOB'
                    ],
                    'tileSize': 256,
                    'attribution':'<a href="https://www.maptiler.com/" target="_blank">Maptiler</a>'
                });

                this.map.addSource('nj-2020-1ft', {
                    'type': 'raster',
                    'tiles': [
                        'https://img.nj.gov/imagerywms/Natural2020?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2020'
                    ],
                    'tileSize': 256
                });

                this.map.addSource('nj-2020-ir-1ft', {
                    'type': 'raster',
                    'tiles': [
                        'https://img.nj.gov/imagerywms/Infrared2020?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Infrared2020'
                    ],
                    'tileSize': 256
                });

                this.map.addSource('nj-2015-1ft', {
                    'type': 'raster',
                    'tiles': [
                        'https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015'
                    ],
                    'tileSize': 256
                });

                this.map.addSource('nj-2015-ir-1ft', {
                    'type': 'raster',
                    'tiles': [
                        'https://img.nj.gov/imagerywms/Infrared2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Infrared2015'
                    ],
                    'tileSize': 256
                });

                this.map.addSource('osm', {
                    'type': 'raster',
                    'tiles': [
                        'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                    ],
                    'tileSize': 256,
                     attribution: '<a href="https://www.openstreetmap.org/"  target="_blank">Open Street Map</a>'
                });

            },

            switchImageryLayer(e){
                const layer = e.target.id;

                this.clearImageryLayers();

                switch (layer){

                    case 'maptiler-layer':
                        this.addMaptilerLayer();
                        break;
                    case 'nj-2020-1ft-layer':
                        this.addNj2020Layer();
                        break;
                    case 'nj-2020-ir-1ft-layer':
                        this.addNj2020IrLayer();
                        break;
                    case 'nj-2015-1ft-layer':
                        this.addNj2015Layer();
                        break;
                    case 'nj-2015-ir-1ft-layer':
                        this.addNj2015IrLayer();
                        break;
                    case 'osm-layer':
                        this.addOSMLayer();
                        break;
                }
            },

            clearImageryLayers(){
                for(const layer in this.imagery_layers){
                    const mapLayer = this.map.getLayer(
                                                this.imagery_layers[layer]);

                    if(typeof mapLayer !== 'undefined') {
                        this.map.removeLayer(this.imagery_layers[layer])
                    }
                }
            },

            addMaptilerLayer(){
                this.map.addLayer({
                    id: 'maptiler-layer',
                    'type': 'raster',
                    'source': 'maptiler',
                    'paint': {
                    'raster-fade-duration': 0
                    }
                },'land');
            },

            addNj2020Layer(){
                this.map.addLayer({
                    id: 'nj-2020-1ft-layer',
                    'type': 'raster',
                    'source': 'nj-2020-1ft',
                    'paint': {}
                    },
                    'aeroway-line'
                );
            },

            addNj2020IrLayer(){
                this.map.addLayer({
                    id: 'nj-2020-ir-1ft-layer',
                    'type': 'raster',
                    'source': 'nj-2020-ir-1ft',
                    'paint': {}
                    },
                    'aeroway-line'
                );
            },

            addNj2015Layer(){
                this.map.addLayer({
                    id: 'nj-2015-1ft-layer',
                    'type': 'raster',
                    'source': 'nj-2015-1ft',
                    'paint': {}
                    },
                    'aeroway-line'
                );
            },

            addNj2015IrLayer(){
                this.map.addLayer({
                    id: 'nj-2015-ir-1ft-layer',
                    'type': 'raster',
                    'source': 'nj-2015-ir-1ft',
                    'paint': {}
                    },
                    'aeroway-line'
                );
            },

            addOSMLayer(){
                this.map.addLayer({
                    id: 'osm-layer',
                    'type': 'raster',
                    'source': 'osm',
                    'paint': {}
                    },
                    'aeroway-line'
                );
            },

            addLulc2020eLayer(){
                this.map.addLayer({
                    id: 'lulc-2020-layer',
                    'type': 'geogson',
                    'source': 'lulc-2020',
                },'lulc-2020');
            },

            addFeatureSources(){
                let url = '';
                
                url = 'https://8to08gnyej.execute-api.us-east-1.';
                url += 'amazonaws.com/default/sadc_utilities?action=';
                url += `get_geojson_overlay&idn_proper=${this.selected_farm_id}&`;
                url += 'overlay=';

                if(this.available_feature_layers.includes('features_2013')){
                    this.map.addSource('features-2013',{
                        type: 'geojson',
                        data: url + 'features-2013',
                        attribution:'Rowan GeoLab',
                        'generateId': true
                    });
                }
                
                if(this.available_feature_layers.includes('features_2021')){
                    this.map.addSource('features-2021',{
                        type: 'geojson',
                        data: url + 'features-2021',
                        attribution:'Rowan GeoLab',
                        'generateId': true
                    });
                }
                
                if(this.available_feature_layers.includes(
                                                    'features_2021_update')){
                    this.map.addSource('features-2021-update',{
                        type: 'geojson',
                        data: url + 'features-2021-update',
                        attribution:'Rowan GeoLab',
                        'generateId': true
                    });
                }

                this.map.addSource('lulc-2020',{
                    type: 'geojson',
                    data: url + 'lulc-2020',
                    attribution:'NJ DEP',
                    'generateId': true
                });

                this.map.addSource('impervious-surface-2015',{
                    type: 'geojson',
                    data: url + 'impervious-surface-2015',
                    attribution:'NJ DEP',
                    'generateId': true
                });
            },

            removeFeatureSources(){
                if (this.map.getLayer("lulc-2020")) {
                    this.map.removeLayer("lulc-2020");
                }
                if (this.map.getLayer("features-2013")) {
                    this.map.removeLayer("features-2013");
                }
                if (this.map.getLayer("features-2021")) {
                    this.map.removeLayer("features-2021");
                }
                if (this.map.getLayer("features-2021-update")) {
                    this.map.removeLayer("features-2021");
                }
                if (this.map.getLayer("impervious-surface-2015")) {
                    this.map.removeLayer("impervious-surface-2015");
                }
            },

            toggleFeatureOverlay(e){
                const layer = e.target.id;

                switch (layer){

                    case 'lulc-2020-layer':
                        this.toggleLulc2020Layer();
                        break;

                    case 'features-2013-layer':
                        this.toggleFeatures2013Layer();
                        this.map.get
                        break;

                    case 'features-2021-layer':
                        this.toggleFeatures2021Layer();
                        break;

                    case 'features-2021-update-layer':
                        this.toggleFeatures2021UpdateLayer();
                        break;

                    case 'impervious-surface-2015-layer':
                        this.toggleImperviousSurface2015Layer();
                        break;
                }
            },

            toggleLulc2020Layer(){

                if (this.map.getLayer("lulc-2020-layer")) {
                    this.map.removeLayer("lulc-2020-layer");
                    this.on_layer = '';
                    this.map.off('click', 'lulc-2020-layer', (e) => {
                        this.handleFeatureClick(e, 'lulc-2020');
                    });
                }else{
                    this.draw.deleteAll();
                    this.map.addLayer({
                        id: 'lulc-2020-layer',
                        'type': 'fill',
                        'source': 'lulc-2020',
                        'paint': {
                            'fill-color': this.paint,
                            'fill-opacity': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                1.0, .4 ],
                            'fill-outline-color': '#000000'
                        },
                        'filter': ['==', '$type', 'Polygon']
                    });
                    this.on_layer = 'lulc-2020-layer';

                    this.map.on('click', 'lulc-2020-layer', (e) => {
                        this.handleFeatureClick(e, 'lulc-2020');
                    });
                }
            },

            toggleFeatures2013Layer(){

                if (this.map.getLayer("features-2013-layer")) {
                    this.map.removeLayer("features-2013-layer");
                    this.on_layer = '';
                    this.map.off('click', 'features-2013-layer', (e) => {
                        this.handleFeatureClick(e, 'features-2013');
                    });
                    this.restoreDrawLayer();
                }else{
                    this.draw.deleteAll();
                    this.map.addLayer({
                        id: 'features-2013-layer',
                        'type': 'fill',
                        'source': 'features-2013',
                        'paint': {
                            'fill-color': this.paint,
                            'fill-opacity': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                1.0, .4 ],
                            'fill-outline-color': '#000000'
                        },
                        'filter': ['==', '$type', 'Polygon']
                    });
                    this.on_layer = 'features-2013-layer';

                    this.map.on('click', 'features-2013-layer', (e) => {
                        this.handleFeatureClick(e, 'features-2013');
                    });
                }
            },

            toggleFeatures2021Layer(){

                if (this.map.getLayer("features-2021-layer")) {
                    this.map.removeLayer("features-2021-layer");
                    this.on_layer = '';
                    this.map.off('click', 'features-2021-layer', (e) => {
                        this.handleFeatureClick(e, 'features-2021');
                    });
                }else{
                    this.draw.deleteAll();
                    this.map.addLayer({
                        id: 'features-2021-layer',
                        'type': 'fill',
                        'source': 'features-2021',
                        'paint': {
                            'fill-color': this.paint,
                            'fill-opacity': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                1.0, .4 ],
                            'fill-outline-color': '#000000'
                        },
                        'filter': ['==', '$type', 'Polygon']
                    });
                    this.on_layer = 'features-2021-layer';

                    this.map.on('click', 'features-2021-layer', (e) => {
                        this.handleFeatureClick(e, 'features-2021');
                    });
                }
            },

            toggleFeatures2021UpdateLayer(){

                if (this.map.getLayer("features-2021-update-layer")) {
                    this.map.removeLayer("features-2021-update-layer");
                    this.on_layer = '';
                    this.map.off('click', 'features-2021-update-layer', (e) => {
                        this.handleFeatureClick(e, 'features-2021-update');
                    });
                }else{
                    this.draw.deleteAll();
                    this.map.addLayer({
                        id: 'features-2021-update-layer',
                        'type': 'fill',
                        'source': 'features-2021-update',
                        'paint': {
                            'fill-color': this.paint,
                            'fill-opacity': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                1.0, .4 ],
                            'fill-outline-color': '#000000'
                        },
                        'filter': ['==', '$type', 'Polygon']
                    });
                    this.on_layer = 'features-2021-update-layer';

                    this.map.on('click', 'features-2021-update-layer', (e) => {
                        this.handleFeatureClick(e, 'features-2021-update');
                    });
                }
            },

            toggleImperviousSurface2015Layer(){

                if (this.map.getLayer("impervious-surface-2015-layer")) {
                    this.map.removeLayer("impervious-surface-2015-layer");
                    this.on_layer = '';
                    this.map.off('click', 'impervious-surface-2015-layer', (e) => {
                        this.handleFeatureClick(e, 'impervious-surface-2015');
                    });
                }else{
                    this.draw.deleteAll();
                    this.map.addLayer({
                        id: 'impervious-surface-2015-layer',
                        'type': 'fill',
                        'source': 'impervious-surface-2015',
                        'paint': {
                            'fill-color': this.paint,
                            'fill-opacity': [
                                'case',
                                ['boolean',['feature-state', 'clicked'], false],
                                1.0, .4 ],
                            'fill-outline-color': '#000000'
                        },
                        'filter': ['==', '$type', 'Polygon']
                    });
                    this.on_layer = 'impervious-surface-2015-layer';

                    this.map.on('click', 'impervious-surface-2015-layer', (e) => {
                        this.handleFeatureClick(e, 'impervious-surface-2015');
                    });
                }
            },

            handleFeatureClick(e, source){

                if(this.farm_status > 2){
                    return;
                }

                this.map.getCanvas().style.cursor = 'pointer';

                if (e.features.length > 0) {
                    const polygonID = e.features[0].id;
                    const state = this.map.getFeatureState({
                            source: source,
                            id: polygonID

                    });

                    if(Object.prototype.hasOwnProperty.call(
                                            state,'clicked')){

                        if(state.clicked){
                            this. map.removeFeatureState({
                                    source: source,
                                    id: polygonID
                            });
                        }
                    }else{
                        this.map.setFeatureState(
                            {source: source, id: polygonID }, 
                            { clicked: true}
                        );
                        this.clicked_feature = e.features[0];
                        this.show_feature_retriever = true;
                        this.disabled = true;
                        this.$emit('disable', this.disabled);
                    }
                }
            },

            restoreDrawLayer(){
                if(this.on_layer == ''){
                    this.$emit('refresh_delineation');
                }
            },

            getAvailableFeatureLayers(){
                const action = 'get_available_feature_layers';
                let url = '';
                url += 'https://'; 
                url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
                url += 'default/sadc_utilities?';
                url += `action=${action}&`;
                url += `idn_proper=${this.selected_farm_id}`;

                axios
                .post(url)
                .then(response => {
                    const data = response.data;
                    
                    if(data.success == 1){  
                        this.available_feature_layers = data.available_layers;
                    }else{
                        this.available_feature_layers = [];
                    }
                })
                .catch(error => {
                    console.error( "Error in 'getAvailableFeatureLayers'", error);
                    return false;
                })
                .then(() => { 
                    this.addFeatureSources();
                    this.available_features_checked = true;
                });
            },

            closeFeatureRetriever(){
                this.show_feature_retriever = false;
                this. map.removeFeatureState({
                    source: "features-2013",
                    id: this.clicked_feature.id
                });
                this.clicked_feature = null;
                this.disabled = false;
                this.clearLayers();
                this.$emit('refresh_delineation');
                this.$emit('disable', this.disabled)
            }, 

            clearLayers(){

                this.feature_layers.forEach(layer => {

                    if (this.map.getLayer(layer)) {
                        this.map.removeLayer(layer);
                    }
                });

                document.querySelectorAll('input[type=checkbox]').forEach(
                    el => el.checked = false);
                
                this.on_layer = '';

            },

            clearSources(){
                let source = '';

                this.clearLayers();

                this.feature_layers.forEach(layer => {
                    source = layer.replace('-layer','');
                    if (this.map.getSource(source)) {
                        this.map.removeSource(source);
                    }
                });
            },

            updateCodes(codes){

                codes.forEach((code) =>{

                    // let parts = code.description.split(" ");
                    // let description = []

                    // for (let i = 0; i < parts.length; i++) {
                    //     description[i] = parts[i][0] == '/'? parts[i][0]:parts[i][0].toUpperCase() + 
                    //                parts[i].substr(1).toLowerCase();
                    // }

                    // description = description.join(" ");
                    // code.description = 
                    //                 description.replace('Agricultural', 'AG');

                    this.codes.push(code);
                });
                 
                this.$emit('codes', this.codes);
            },

            addGeolabAttribution(){
                let el = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];

                el.style.background = 'transparent';
                el.style.width = 'auto';
                el.classList.add('rounded');
                el.classList.add('text-white');
                el.classList.add('px-5');
                el.classList.add('pt-1');
                el.classList.add('text-xs');

                el.innerHTML = 'Corbalis, Rowan GeoLab (2022)';
            }

        },

        mounted(){
            this.createMap();
            this.tweakDrawMenuStyle();
            this.addOpacityListener();
            this.addGeolabAttribution();
        }
    }

</script>
