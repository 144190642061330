<template>
  <div>
    <div class="absolute top-1/2 left-1/2 p-4 bg-blue-500 bg-opacity-60 
                 rounded-full z-50" 
         v-if="working">
         <img :src="tail_spin" 
                class="h-20 w-20 mt-2 duration-200 transition-all">
    </div>
    <mq-responsive>
      <template #md+>
        <div  class="hidden resize-none md:fixed md:block md:left-0 md:z-10
                    md:w-1/3 md:h-full text-center
                    bg-gray-600 shadow-md opacity-95  
                    overflow-y-scroll transition-all ease duration-500"
              id="info-left">
          <div class="grid grid-cols-4 mb-1 bg-white">
            <div class="col-span-4 text-2xl font-bold text-blue-700
                        text-left pl-6 pt-2">
                Farm Disturbance Digitizer
            </div>
          </div>
          <div class="grid grid-cols-4 mb-2">
            <div class="col-span-4 bg-white">
              <farm-menu :map="map" 
                        :default_view="default_view"
                        :draw="draw"
                        :userid="userid"
                        :is_admin="is_admin"
                        :admin_on="admin_on"
                        :deselect_farm="deselect_farm"
                        @selected_farm="updateFarmID"
                        @toggle_admin="toggleAdmin"
                        @working="updateWorking"
                        v-show="selected_farm_id==0">
              </farm-menu>
              <farm-info
                :selected_farm="selected_farm_id"
                v-if="selected_farm_id>0">
              </farm-info>
            </div>
            <div class="col-span-4 bg-white py-6 px-6 
                        font-roboto text-lg text-gray-700 mt-1">
              <digitizer
                        :map="this.map" 
                        :userid="this.userid"
                        :selected_farm="this.selected_farm"
                        :new_feature="this.new_feature"
                        :reclassified_feature="this.reclassified_feature"
                        :edited_feature="this.edited_feature"
                        :delete_feature="this.delete_feature"
                        :draw="this.draw"
                        :disabled="this.disable_digitizer"
                        :refresh_delineation="this.refresh_delineation"
                        :codes="this.codes"
                        @features_updated="processUpdatedFeatures"
                        @deselect_farm="deselectFarm"
                        @reopen_farm="reopenFarm"
                        @action_completed="actionCompleted"
                        @working="updateWorking">
              </digitizer>
            </div>
          </div>
        </div>

        <div class="hidden md:block absolute top-5 left-1/3 z-20 bg-white 
                    rounded-r h-10 w-6 shadow-md" 
            id="info-left-handle"
            @click="toggleInfoLeft" 
            @mouseenter="show_info_message = true"
            @mouseleave="show_info_message = false">
          <img :src="toggle_png" 
                class="h-7 w-7 mt-1.5 duration-200 transition-all">
        </div>
        
        <div v-show="show_info_message && info_left_open"
            class="hidden md:block rounded-md h-7 w-45 content-center
                    absolute top-6 left-1/3 z-40 p-0.5 ml-6">
          <div class="bg-gray-900 text-gray-200 text-base w-full mx-2
                      my-auto p-0.5 rounded text-center sb1">
            Collapse Side Panel
          </div>
        </div>

        <div v-show="show_info_message && !info_left_open"
            class="rounded-md h-7 w-45 content-center
                    absolute top-6 left-0 z-40 p-0.5 ml-6">
          <div class="bg-gray-900 text-gray-200 text-base w-full mx-2 
                      my-auto p-0.5 rounded text-center sb1">
            Expand Side Panel
          </div>
        </div>
      </template>
    </mq-responsive>

    <mq-responsive>
      <template #sm>
        <div class="w-full h-1/4 fixed bottom-0 resize-y text-center md:hidden
                    bg-white shadow-md opacity-95 overflow-visible z-50  
                    transition-all ease duration-500"
            id="info-bottom"
            @mousedown="resizeMouseDown"
            @touchstart="resizeMouseDown">
          <div class="grid grid-cols-4 mb-1 bg-white">
            <div class="col-span-4 text-2xl font-bold text-blue-700
                        text-left pl-6 py-2">
              Farm Disturbance Digitizer
            </div>
          </div>
          <div class="grid grid-cols-4 mb-2">
            <div class="grid grid-cols-4 col-span-4 bg-white py-6 px-6 
                        font-roboto text-lg text-gray-700">
              <div class="col-span-4 bg-white">
                <farm-menu :map="map" 
                          :default_view="default_view"
                          :draw="draw"
                          :userid="userid"
                          :deselect_farm="deselect_farm"
                          @selected_farm="updateFarmID"
                          @working="updateWorking"
                          v-show="selected_farm_id==0">
                </farm-menu>
                <farm-info
                  :selected_farm="selected_farm_id"
                  v-if="selected_farm_id>0">
                </farm-info>
              </div>
              <div class="col-span-4 bg-white py-6 px-6 
                          font-roboto text-lg text-gray-700 mt-1">
                <digitizer
                          :map="this.map" 
                          :userid="this.userid"
                          :selected_farm="this.selected_farm"
                          :new_feature="this.new_feature"
                          :reclassified_feature="this.reclassified_feature"
                          :edited_feature="this.edited_feature"
                          :delete_feature="this.delete_feature"
                          :draw="this.draw"
                          :disabled="this.disable_digitizer"
                          :refresh_delineation="this.refresh_delineation"
                          @features_updated="processUpdatedFeatures"
                          @deselect_farm="deselectFarm"
                          @reopen_farm="reopenFarm"
                          @action_completed="actionCompleted"
                          @working="updateWorking">
                </digitizer>
              </div>
            </div>       
          </div>
        </div>
      </template>
    </mq-responsive>
    <div class="w-screen h-screen md:w-2/3 resize-y md:resize-x md:float-right
                 md:absolute md:inset-y-0 md:right-0 md:top-0 md:z-0 
                 transition-all ease duration-500"
          id="map-container">
      <admin-info
        v-if="show_admin">
      </admin-info>
      <map-container
        v-if="!show_admin"
        :selected_farm="selected_farm"
        :feature_update="feature_update"
        :userid="userid"
        :clear_sources="clear_sources"
        :reopen_farm="reopen_farm"
        :action_completed="action_completed"
        @map_created="updateMap"
        @new_feature="updateNewFeature"
        @reclassified_feature="updateReclassifiedFeature"
        @edited_feature="updateEditedFeature"
        @delete_feature="updateDeleteFeature"
        @disable="enableDisableDigitizer"
        @refresh_delineation="toggleRefreshDelineation"
        @codes="updateCodes">
      </map-container>
    </div>
  </div>
</template>

<script>
  import Map from './Map.vue'
  import FarmMenu from './FarmMenu.vue'
  import FarmInfo from './FarmInfo.vue'
  import Digitizer from './Digitizer.vue'
  import AdminInfo from './AdminInfo.vue'
  import axios from 'axios';
  import { MqResponsive } from "vue3-mq";
  import toggle_png from "../assets/img/arrow_left_2x.png"
  import tail_spin from '../assets/img/tail-spin.svg'

  export default {
  
    name: 'App',

    components: {
      "map-container": Map,
      "farm-menu": FarmMenu,
      "farm-info": FarmInfo,
      "digitizer": Digitizer,
      "admin-info": AdminInfo,
      MqResponsive
    },

    data() {

      return {
        resize_top: 0,
        y_start: 0,
        toggle_png,
        tail_spin,
        info_left_open: true,
        show_info_message: false,
        map: null,
        default_view: null,
        draw: null,
        disable_digitizer: false,

        userid: 0,
        is_admin: false,
        selected_farm_id: 0,
        selected_farm: null,
        reopen_farm: false,
        farm_detail: null,
        feature_update: false,
        new_feature: null,
        reclassified_feature: null,
        edited_feature: null,
        delete_feature: null,
        deselect_farm: false,
        clear_sources: false,
        refresh_delineation: false,
        action_completed: false,
        working: false,
        codes: null,
        admin_on: false
      };
    },

    computed: {
        show_admin() {
          if(this.admin_on == true && this.is_admin == true){
            return true;
        }else{
          return false;
        }
      }
    },

    methods:{

      toggleInfoLeft(){
        let panel = document.getElementById('info-left'),
            handle = document.getElementById('info-left-handle'),
            map = document.getElementById('map-container');

        this.show_info_message = false;
        
        if (this.info_left_open){
          panel.classList.add('md:w-0')
          panel.classList.add('md:opacity-0')
          panel.classList.remove('md:w-1/3')
          panel.classList.remove('md:opacity-95')
          map.classList.remove('md:w-2/3');
          map.classList.remove('md:absolute');
          map.classList.remove('md:inset-y-0');
          map.classList.remove('md:right-0');
          map.classList.remove('md:z-0');
          map.classList.remove('md:resize-x');
          handle.classList.remove('left-1/3');
          handle.classList.add('left-0');
          handle.classList.add('transform');
          handle.classList.add('rotate-180');
          handle.title = "Show side panel";
        }else{
          panel.classList.remove('md:w-0')
          panel.classList.remove('md:opacity-0')
          panel.classList.add('md:w-1/3')
          panel.classList.add('md:opacity-95')
          map.classList.add('md:w-2/3');
          map.classList.add('md:absolute');
          map.classList.add('md:inset-y-0');
          map.classList.add('md:right-0');
          map.classList.add('md:z-0');
          map.classList.add('md:resize-x');
          handle.classList.remove('left-0');
          handle.classList.add('left-1/3');
          handle.classList.remove('transform');
          handle.classList.remove('rotate-180');
          handle.title = "Hide side panel";
        }
        this.resizeMap();
        this.info_left_open = !this.info_left_open;
      },

      resizeMouseDown(e){
    
        if(!e.target.closest('#info-bottom')){
          return;
        }
        this.startTop = document.getElementById('info-bottom').offsetTop;
        this.startY = e.clientY??e.touches[0].clientY;
        document.addEventListener('mousemove', this.resizeMouseMove);
        document.addEventListener('touchmove', this.resizeMouseMove);
        document.addEventListener('mouseup', this.resizeMouseUp);
        document.addEventListener('touchend', this.resizeMouseUp);
      },

      resizeMouseMove(e){
        var mapBottom = document.getElementById('map')
                        .getBoundingClientRect().bottom;
        var newTop = null;
        if (e.type === "touchmove") {
            newTop = this.startTop + e.touches[0].clientY - this.startY;
        } else {
            newTop = this.startTop + e.clientY - this.startY;
        }

        if(newTop > Math.round(mapBottom) + 5){
          document.getElementById('map').style.height = `${newTop}px`;
        }
        document.getElementById('info-bottom').style.top = `${newTop}px`;
        document.getElementById('info-bottom').style.height ='initial';
      },

      resizeMouseUp(){
        document.removeEventListener('mousemove', this.resizeMouseMove);
        document.removeEventListener('touchmove', this.resizeMouseMove);
        document.removeEventListener('mouseup', this.resizeMouseMove);
        document.removeEventListener('touchend', this.resizeMouseMove);
        this.resizeMap();
      },

      resizeMap(){
        setTimeout(() => this.map.resize(),600);
      },

      updateMap(data){
        this.map = data.map;
        this.default_view = data.view;
        this.draw = data.draw;
      },

      updateFarmID(selection){
        this.deselect_farm = false;
        this.selected_farm_id = selection.idn_proper;
        this.selected_farm = selection;

      },

      updateFarmDetail(data){
        this.farm_detail = data;
      },

      updateNewFeature(feature){
        this.new_feature = feature;
      },

      updateReclassifiedFeature(reclassified_data){
        this.reclassified_feature = reclassified_data;
      },
      
      updateEditedFeature(feature){
        this.edited_feature = feature;
      },

      updateDeleteFeature(feature){
        this.delete_feature = feature;
      },

      processUpdatedFeatures(){
        this.feature_update = !this.feature_update ;
      },

      actionCompleted(){
        this.action_completed = !this.action_completed;
      },

      updateWorking(state=false){
        this.working= state;
      },

      deselectFarm(){
        this.selected_farm_id = 0;
        this.selected_farm = null;
        this.deselect_farm = true;
        this.clear_sources = !this.clear_sources;
      },
      
      enableDisableDigitizer(bool){
        this.disable_digitizer = bool;
      },

      reopenFarm(){
        this.reopen_farm =  !this.reopen_farm;
      },

      toggleRefreshDelineation(){
        this.refresh_delineation = !this.refresh_delineation;
      },

      updateCodes(codes){
        this.codes = codes
      },

      toggleAdmin(){
        this.admin_on = !this.admin_on;
      },

      closeAdmin(){
        this.admin_on = false;
      },

      getUserid(rejected){
        const msg = rejected?'ID not found. ':'';
        let userid = prompt(msg + "Please enter your user id");

        if (userid == null || userid == "") {
          return false;
        }else{
          this.userid = parseInt(userid);
          this.getUser();
          return true;
        }
      },
 
      getUser(){

          const action = 'get_user';

          let url = '';
          url += 'https://'; 
          url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
          url += 'default/sadc_utilities?';
          url += `action=${action}&`; 
          url += `userid=${this.userid}`;  
          url = encodeURI(url);

          axios
          .get(url)
          .then(response => {
              const data = response.data?response.data[0]:null;
              
              if(!data){
                this.getUserid(true);
                return false;
              }
              if(!data.active){
                this.getUserid(true);
                return false;
              }
              
              this.is_admin = data.administrator;
              return true;
          })
          .catch(error => {
              console.error( "Error in 'get_user'", error)
              return false;
          })
      },
    },

    mounted(){
       this.getUserid();

    }
  }

</script>

<style>

  .sb1:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid  rgba(17, 24, 39, 1);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -5px;
    bottom: 3px
  }
  
</style>  
