<template>

    <div class="grid grid-cols-4 col-span-4" v-show="selected_farm_id>0">
        <div class="col-span-2 bg-slate-100 text-base text-blue-700 text-left 
                    pb-2  pl-10">
            Farm Area
        </div>
        <div class="col-span-2 bg-slate-100 text-base text-gray-600 text-left 
                    pb-2  pl-10">
            {{code_details.farm_area?code_details.farm_area:0}} acs.
        </div>
        <div class="col-span-2 bg-slate-100 text-base text-blue-700 text-left 
                    pb-2  pl-10">
            Featured Area
        </div>
        <div class="col-span-2 bg-slate-100 text-base text-gray-600 text-left 
                    pb-2  pl-10">
            {{code_details.featured_area?code_details.featured_area:0}} acs.
        </div>
        <div class="col-span-2 bg-slate-100 text-base text-blue-700 text-left 
                    pb-2  pl-10">
            Digitized
        </div>
        <div class="col-span-2 bg-slate-100 text-base text-gray-600 text-left 
                    pb-2  pl-10">
            {{code_details.featured_percent?code_details.featured_percent:0}} %
        </div>
        <div class="col-span-4 bg-white flex place-content-center my-4">
            <button class="bg-blue-500 text-white font-bold py-2 px-4 mr-4
                        rounded"
                    @click="refreshDelineation"
                    :disabled="disabled">
                Refresh
            </button>
            <button class="bg-blue-500 text-white font-bold py-2 px-4 mr-4
                        rounded"
                    @click="closeDelineation"
                    :disabled="disabled">
                Close
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white 
                        font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed"
                    id="farm-select-btn"
                    v-show="!completed">
                Finish
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white 
                        font-bold py-2 px-4 rounded opacity-100 
                        cursor-pointer"
                    id="farm-select-btn"
                    @click="finishDelineation"
                    v-show="completed && farm_status < 3"
                    :disabled="disabled">
                Finish
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white 
                        font-bold py-2 px-4 rounded opacity-100 
                        cursor-pointer"
                    id="farm-select-btn"
                    @click="reopenDelineation"
                    v-show="completed && farm_status == 3"
                    :disabled="disabled">
                Reopen
            </button>
        </div>
        <div class="col-span-4 bg-white flex place-content-center my-4 "
             v-show="completed && farm_status > 1 && reopened 
                     && userid != farm_userid">
            <button class="bg-blue-500 hover:bg-blue-700 text-white 
                        font-bold py-2 px-4 mx-2 rounded opacity-100 
                        cursor-pointer"
                    id="farm-select-btn"
                    @click="rejectDelineation"
                    :disabled="disabled">
                Reject
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white 
                        font-bold py-2 px-4 mx-2 rounded opacity-100 
                        cursor-pointer"
                    id="farm-select-btn"
                    @click="approveDelineation"
                    :disabled="disabled">
                Approve
            </button>
        </div>
        <div class="col-span-4 bg-slate-100 text-base text-blue-700 text-left 
                    pb-2  pl-2">
            Feature Summary
        </div>
        <div class="col-span-4 bg-slate-100 text-sm">
            <div class="grid grid-cols-8 w-full mx-1 font-semibold" >
                <div class="col-span-4 text-justify pr-4">Code</div>
                <div class="col-span-2 text-left pl-3">Acres</div>
                <div class="col-span-2 text-left -ml-2">% of Farm</div>
            </div>
            <div class="grid grid-cols-8 w-full m-1" 
                    v-for="item in code_details.code_summary" 
                    :key="item.code">
                <div class="col-span-4 text-left pr-2">({{item.code}}) {{code_key[item.code].trim()}}</div>
                <div class="col-span-2 text-left pl-3">{{item.code_area}}</div>
                <div class="col-span-2 text-left">{{item.pct}}</div>
            </div>
        </div>
    </div>

</template>

<script>

    import axios from 'axios';

export default {

    name:'digitizer',

    data() {
      return {
          feature: {
              "geometry": null,
              "code": null,
              "userid": this.userid,
              "featureid": 0
          },
          selected_farm_id: 0,
          farm_status: 0,
          farm_userid: 0,
          code_details: {},
          code_key: {},
          reopened: false,
          completed: false
      }
    },

    props:['map', 'userid', 'selected_farm', 'new_feature', 'delete_feature', 
           'reclassified_feature', 'edited_feature', 'draw', 'disabled',
           'refresh_delineation', 'codes'],
    
    emits:['features_updated', 'deselect_farm', 'reopen_farm', 'working',
            'action_completed'],

    watch:{
        
        selected_farm: function(){
                if (!this.selected_farm){
                    return;
                }
                this.parseSelectedFarm();

                if(this.selected_farm_id > 0){
                    this.getDelineation();
                }else{
                    this.draw.deleteAll();
                }
        },
        
        new_feature: function(){
            this.getFeatureCode();
            this.getFeatureGeometry();
            this.getFeatureID();
            this.draw_feature_id = this.new_feature.feature.id;
            this.addUpdateFeature();
        },

        reclassified_feature: function(){
            this.reclassifyFeature();
        },

        edited_feature: function(){
            const featureid = this.edited_feature.properties.featureid;
            this.feature.code = this.edited_feature.properties.code;
            this.feature.geometry = 
                                JSON.stringify(this.edited_feature.geometry);
            this.draw_feature_id = this.edited_feature.id;
            this.addUpdateFeature(featureid);
        },
        
        delete_feature: function(){
            this.deleteFeature();
        },
        
        refresh_delineation: function(){
            this.getDelineation();
        },

        codes: function(){
            this.code_key = {};

            this.codes.forEach(code => {
                if(!code){ 
                    return; 
                }
                this.code_key[code.code] = code.description.replace('AGRICULTURAL', 'AG')
            })
        }

    },

    methods:{

        parseSelectedFarm(){
            this.selected_farm_id = this.selected_farm.idn_proper;
            this.farm_status = this.selected_farm.status;
        },

        getFeatureCode(){
            this.feature.code = this.new_feature.code;
        },
        
        getFeatureGeometry(){ 
            this.feature.geometry = 
                            JSON.stringify(this.new_feature.feature.geometry);
        },
        
        getFeatureID(){ 
            this.feature.featureid = this.new_feature.featureid;
        },

        addUpdateFeature(id = null){

            const action = 'add_update_feature';
            let featureid = id;

            this.$emit('working', true);

            if(!featureid){
                if(Object.prototype.hasOwnProperty.call(
                                                this.new_feature,'feature')){
                    if(Object.prototype.hasOwnProperty.call(
                                        this.new_feature.feature,'properties')){
                        if(Object.prototype.hasOwnProperty.call(
                            this.new_feature.feature.properties,'featureid')){
                            featureid =  this.new_feature.feature.properties
                                                                    .featureid;
                        }
                    }
                }
            }

            featureid = featureid?featureid:0;

            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `idn_proper=${this.selected_farm_id}&`;
            url += `code=${this.feature.code}&`;
            url += `userid=${this.userid}&`;
            url += `geometry=${this.feature.geometry}&`;
            url += `featureid=${featureid}`;          
            url = encodeURI(url);

            axios
            .get(url)
            .then(response => {
                const data = JSON.parse(response.data);
                if(data.success == 0){  
                    if(data.message != ''){ 
                        // alert( "Error in 'addUpdateFeature': " + data.message);
                        console.log( "Error in 'addUpdateFeature': " + 
                                                                data.message);
                        this.deleteDrawFeature();
                        this.$emit('features_updated');
                        this.$emit('working', false);
                        this.getDelineation();
                        return false;
                    }
                }

                const iterator = data.features.entries();
                for (const value of iterator) {

                    const geoJSON = JSON.parse(value[1]);
                    const item = this.draw.add(geoJSON);
                    
                    for (const [key, value] of 
                                        Object.entries(geoJSON.properties)) {
                        this.draw.setFeatureProperty(item[0], key, value);
                    }
                }
                this.deleteDrawFeature();
                this.$emit('features_updated');
            })
            .catch(error => {
                console.error( "Error in 'addUpdateFeature'", error)
                return false;
            })
            .then(
                ()=>{
                    this.getDelineation();
                    this.$emit('working', false);
                    this.$emit('action_completed');
                }
            );
        },

        reclassifyFeature(){
            const action = 'reclassify_feature';

            this.$emit('working', true);

            if(!Object.prototype.hasOwnProperty.call(
                                        this.reclassified_feature,'featureid')
               || !Object.prototype.hasOwnProperty.call(
                                        this.reclassified_feature,'code') 
            ){
                alert( "Error in 'addUpdateFeature': Missing parameter(s).");
                this.$emit('working', true);
                return false;
            }
                
            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `featureid=${this.reclassified_feature.featureid}&`;
            url += `code=${this.reclassified_feature.code}&`;
            url += `userid=${this.userid}`;     
            url = encodeURI(url);

            axios
            .get(url)
            .then(response => {
                const data = response.data;
                if(data.success == 0){  
                    if(data.message != ''){ 
                        // alert( "Error in 'reclassify_feature'", data.message);
                        console.log( "Error in 'reclassify_feature'", 
                                                                data.message);
                    }
                }
            })
            .catch(error => {
                console.log( "Error in 'reclassify_feature'", error)
            })
            .then(
                ()=>{
                    this.getDelineation();
                    this.$emit('working', false);
                    this.$emit('action_completed');
                }
            );
        },

        deleteFeature(){
            const action = 'delete_feature';
            const featureid = this.delete_feature.properties.featureid;

            this.$emit('working', true);

            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `idn_proper=${this.selected_farm_id}&`;
            url += `userid=${this.userid}&`;
            url += `featureid=${featureid}`;          
            url = encodeURI(url);

            axios
            .get(url)
            .then(response => {
                const data = response.data;
                
                if(data.success == 0){  
                    // alert( "Error in 'deleteFeature': " + data.message);
                    console.log( "Error in 'deleteFeature': " + data.message);
                }
            })
            .catch(error => {
                console.error( "Error in 'deleteFeature'", error);
            })
            .then(
                ()=>{
                    this.getDelineation();
                    this.$emit('working', false);
                    this.$emit('action_completed');
                }
            );
        },

        getDelineation(){

            const action = 'get_delineation';

            this.$emit('working', true);

            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `idn_proper=${this.selected_farm_id}&`;  
            url += `userid=${this.userid}`;   
            url = encodeURI(url);

            axios
            .get(url)
            .then(response => {
                
                if(Object.prototype.hasOwnProperty.call(
                                                    response.data,'success')){ 
                    if(response.data.success == 0){                                           
                        console.error( "Error in 'getDelineation'", 
                                                        response.data.message);
                    }
                }
                this.draw.set(response.data[0].features);
                this.code_details = response.data[0].details;
                this.updateDelineationAttributes();

            })
            .catch(error => {
                console.error( "Error in 'getDelineation'", error);
            })
            .then(()=>{
                this.$emit('working', false);
            });
        },

        finishDelineation(){

            const action = 'finish_delineation';

            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `idn_proper=${this.selected_farm_id}&`;  
            url += `userid=${this.userid}`;   
            url = encodeURI(url);
            
            this.$emit('working', true);

            axios
            .get(url)
            .then(response => {
                const data = response.data;
                
                if(data.success == 0){  
                    // alert( "Error in 'finishDelineation': " + data.message);
                    console.log( "Error in 'finishDelineation': " + 
                                                                data.message);
                }
                this.draw.deleteAll();
                this.code_details = {};

                return true;
            })
            .catch(error => {
                console.error( "Error in 'finishDelineation'", error)
                return false;
            })
            .then(() => {
                this.closeDelineation(true);
                this.$emit('working', false);
            });
        },
        
        approveDelineation(){

            const action = 'approve_delineation';
            
            this.$emit('working', true);

            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `idn_proper=${this.selected_farm_id}&`;  
            url += `userid=${this.userid}`;   
            url = encodeURI(url);

            axios
            .get(url)
            .then(response => {
                const data = response.data;
                
                if(data.success == 0){  
                    // alert( "Error in 'approveDelineation': " + data.message);
                    console.log( "Error in 'approveDelineation': " + 
                                                                data.message);
                }
                this.draw.deleteAll();
                this.code_details = {};
            })
            .catch(error => {
                console.error( "Error in 'approveDelineation'", error);
            })
            .then(() => {
                this.closeDelineation(true);
                this.$emit('working', false);
            });
        },
        
        rejectDelineation(){

            const action = 'reject_delineation';
            let notes = prompt('Any Notes?', '');
            
            this.$emit('working', true);

            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `idn_proper=${this.selected_farm_id}&`;
            url += `notes=${notes}&`;    
            url += `userid=${this.userid}`;   
            url = encodeURI(url);

            axios
            .get(url)
            .then(response => {
                const data = response.data;
                
                if(data.success == 0){  
                    // alert( "Error in 'rejectDelineation': " + data.message);
                    console.log( "Error in 'rejectDelineation': " + 
                                                                data.message);
                }
                this.draw.deleteAll();
                this.code_details = {};
            })
            .catch(error => {
                console.error( "Error in 'rejectDelineation'", error);
            })
            .then(() => {
                this.closeDelineation(true);
                this.$emit('working', false);
            });
        },           
        
        refreshDelineation(){
            this.getDelineation();
        },

        closeDelineation(finished = false){
            this.selected_farm_id = 0;
            this.reopened =  false;
            this.$emit('deselect_farm', finished);
            this.draw.deleteAll();
        },

        reopenDelineation(){
            this.farm_status = 2;
            this.reopened = true;
            this.$emit('reopen_farm');
            this.$emit('working', true);


            const action = 'reopen_delineation';

            let url = '';
            url += 'https://'; 
            url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
            url += 'default/sadc_utilities?';
            url += `action=${action}&`;
            url += `idn_proper=${this.selected_farm_id}&`;  
            url += `userid=${this.userid}`;   
            url = encodeURI(url);

            axios
            .get(url)
            .then(response => {
                const data = response.data;
                
                if(data.success == 0){  
                    // alert( "Error in 'reopenDelineation': " + data.message);
                    console.log( "Error in 'reopenDelineation': " + 
                                                                data.message);
                }
            })
            .catch(error => {
                console.error( "Error in 'reopenDelineation'", error);
            })
            .then(() => {
                this.$emit('working', false);
            });
        },

        updateDelineationAttributes(){
            this.completed = false;
            if(Object.keys(this.code_details).length != 0){
                if(this.code_details.featured_percent > 99.9){
                    this.completed = true;
                }
                this.farm_userid = this.code_details.userid;
            }
        },

        deleteDrawFeature(){
            this.draw.delete([this.draw_feature_id]);
            this.draw_feature_id = null;
        },
    }

}
</script>