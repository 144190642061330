<template>

    <div class="w-full h-auto grid grid-cols-3 justify-center text-sm">
        <div class="text-right my-0.5 pr-2 text-gray-600">
            ID:
        </div>
        <div class="col-span-2 text-left my-0.5 pl-2 w-16">
            {{farm_data.idn_proper}}
        </div>
        <div class="text-right my-0.5 pr-2 text-gray-600">
            Name(s):
        </div>
        <div class="col-span-2 text-left my-0.5 pl-2">
            {{farm_data.owner}}
        </div>
        <div class="text-right my-0.5 pr-2 text-gray-600">
            Muni:
        </div>
        <div class="col-span-2 text-left my-0.5 pl-2">
            {{farm_data.municipality}}
        </div>
        <div class="text-right my-0.5 pr-2 text-gray-600">
            Cty(s):
        </div>
        <div class="col-span-2 text-left my-0.5 pl-2">
            {{farm_data.county}}
        </div>
        <div class="text-right my-0.5 pr-2 text-gray-600"
             v-show="farm_data['reviewer']">
            Reviewed:
        </div>
        <div class="col-span-2 text-left my-0.5 pl-2"
             v-show="farm_data['reviewer']">
            {{farm_data['reviewer']}} -  {{farm_data['reviewed_date']}}
        </div>
        <div class="text-right my-0.5 pr-2 text-gray-600"
             v-show="farm_data['reviewer']">
            Approved:
        </div>
        <div class="col-span-2 text-left my-0.5 pl-2"
             v-show="farm_data['reviewer']">
            {{farm_data['approved']}}
        </div>
        <div class="text-right my-0.5 pr-2 text-gray-600"
             v-show="farm_data['reviewer_notes']">
            Notes:
        </div>
        <div class="col-span-2 text-left my-0.5 pl-2"
             v-show="farm_data['reviewer_notes']">
            {{farm_data['reviewer_notes']}}
        </div>
    </div>
  
</template>

<script>

    import axios from 'axios';
    
    export default {
       
        name: 'FarmInfo',

        props: ['selected_farm'],

        watch:{
        
            selected_farm: function(){
                if(this.selected_farm > 0){
                    this.getFarmInfo();
                }
            }
        },

        data(){
            return{
                farm_data: {},
            }
        },

        methods:{
        
            getFarmInfo(){
                const action = 'get_farm_info';
                let url = '';
                url += 'https://'; 
                url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
                url += 'default/sadc_utilities?';
                url += `action=${action}&`;
                url += `idn_proper=${this.selected_farm}`;
                
                axios
                .post(url)
                .then(response => {
                    if(Object.prototype.hasOwnProperty.call(
                                                response.data,'message')){                                            
                        console.error( "Error in 'get_farm_info'",
                                        response.data.message)
                    }else{
                        this.farm_data = response.data[0];
                    }
                })
                .catch(error => {
                    console.error( "Error in 'get_farm_info'", error)
                    return false;
                });
            }
        },

        mounted(){
            this.getFarmInfo();
        }
    }
</script>