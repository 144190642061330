import { createApp } from 'vue'
// import { createWebHistory, createRouter }  from 'vue-router'
import { Vue3Mq } from "vue3-mq";
import App from '../../components/App.vue'

import '../css/index.css'

// const routes = [
//     { path: '/', component: App },
//     { path: '/about', component: App },
//   ]
  
//   // 3. Create the router instance and pass the `routes` option
//   // You can pass in additional options here, but let's
//   // keep it simple for now.
//   const router = createRouter({
//     // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
//     history: createWebHistory(),
//     routes, // short for `routes: routes`
//   })  

createApp(App)
    .use(Vue3Mq, {preset: "tailwind"})
    // .use(router)
    .mount('#app')