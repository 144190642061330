<template>
    <div class="m-6">
        <div class="w-full text-left m-2 align-bottom"
             v-if="is_admin">
            <input type="checkbox" id="admin-info" @change="toggleAdmin">
            <label for="admin_info"
                   class="text-sm text-blue-500 mx-1"
                >Show Administartive Info</label>
        </div>
        <div class="w-full">
            <label for="available-farms"
                class="float-left text-sm font-semibold text-blue-800
                        mb-0.5">
                Available Farms
            </label>
            <select id="available-farms" 
                    v-model="selected_available_farm"
                    class="w-full border border-gray-400 text-gray-700 
                        rounded shadow text-sm p-1 ml-1">
                <option value=0 selected>
                    {{available_farms.length>0?'Select':'None Available'}}
                </option>
                <option v-for="farm in available_farms" 
                    v-bind:value="farm.idn_proper"
                    :key="farm.idn_proper">
                    {{ farm.idn_proper + ' ' + farm.owner + 
                       ' ('+ farm.county + ')'}}
                </option>
            </select>
        </div>
        <div>
            <label for="in-progress-farms"
                class="float-left text-sm font-semibold text-blue-800
                        mt-2 mb-0.5">
                In-Progress Farms
            </label>
            <select id="in--farms" 
                    v-model="selected_in_progress_farm"
                    class="w-full border border-gray-400 text-gray-700 
                        rounded shadow text-sm p-1 ml-1">
                <option value=0 selected>
                    {{in_progress_farms.length>0?'Select':'None Available'}}
                </option>
                <option v-for="farm in in_progress_farms" 
                    v-bind:value="farm.idn_proper"
                    :key="farm.idn_proper">
                    {{ farm.idn_proper + ' ' + farm.owner}}
                </option>
            </select>
        </div>
        <div>
            <label for="completed-farms"
                class="float-left text-sm font-semibold text-blue-800
                        mt-2 mb-0.5">
                Completed Farms
            </label>
            <select id="completed-farms" 
                    v-model="selected_completed_farm"
                    class="w-full border border-gray-400 text-gray-700 
                        rounded shadow text-sm p-1 ml-1">
                <option value=0 selected>
                    {{completed_farms.length>0?'Select':'None Available'}}
                </option>
                <option v-for="farm in completed_farms" 
                    v-bind:value="farm.idn_proper"
                    :key="farm.idn_proper">
                    {{ farm.idn_proper + ' ' + farm.owner}} 
                    ({{ farm.user }})
                </option>
            </select>
        </div>

        <div>
            <label for="rejected-farms"
                class="float-left text-sm font-semibold text-blue-800
                        mt-2 mb-0.5">
                Rejected Farms
            </label>
            <select id="rejected-farms" 
                    v-model="selected_rejected_farm"
                    class="w-full border border-gray-400 text-gray-700 
                        rounded shadow text-sm p-1 ml-1">
                <option value=0 selected>
                    {{rejected_farms.length>0?'Select':'None Available'}}
                </option>
                <option v-for="farm in rejected_farms" 
                    v-bind:value="farm.idn_proper"
                    :key="farm.idn_proper">
                    {{ farm.idn_proper + ' ' + farm.owner}} 
                    ({{ farm.user }})
                </option>
            </select>
        </div>
        <div>
            <label for="approved-farms"
                class="float-left text-sm font-semibold text-blue-800
                        mt-2 mb-0.5">
                Approved Farms
            </label>
            <select id="approved-farms" 
                    v-model="selected_approved_farm"
                    class="w-full border border-gray-400 text-gray-700 
                        rounded shadow text-sm p-1 ml-1">
                <option value=0 selected>
                    {{approved_farms.length>0?'Select':'None Available'}}
                </option>
                <option v-for="farm in approved_farms" 
                    v-bind:value="farm.idn_proper"
                    :key="farm.idn_proper">
                    {{ farm.idn_proper + ' ' + farm.owner}}
                    ({{ farm.user }})
                </option>
            </select>
        </div>
        <div class="w-full content-center m-2">
            <button class="bg-blue-500 text-white font-bold py-2 px-4 
                           rounded opacity-50 cursor-not-allowed"
                    v-show="selected_farm==0">
                Select
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white 
                           font-bold py-2 px-4 rounded opacity-100 
                           cursor-pointer"
                    id="farm-select-btn"
                    @click="select_farm"
                    v-show="selected_farm>0">
                Select
            </button>
        </div>
    </div>

</template>

<script>

    import axios from 'axios';
    import bbox from '@turf/bbox'

    export default {
               
        name: 'FarmMenu',

        props:['map', 'default_view', 'draw', 'deselect_farm', 'userid', 
               'is_admin'],

        emits:['selected_farm', 'toggle_admin', 'working'],

        data(){
            return{
                farm_boundary_geoJSON: null,
                menuResponse: [],
                available_farms: [],
                in_progress_farms: [],
                completed_farms: [],
                approved_farms: [],
                rejected_farms: [],
                selected_farm: 0,
                selected_available_farm: 0,
                selected_in_progress_farm: 0,
                selected_completed_farm: 0,
                selected_approved_farm: 0,
                selected_rejected_farm: 0
            }
        },

        watch:{
        
            selected_farm: function(){
                if(this.selected_farm > 0){
                    this.getFarmBoundary();
                }else{
                    this.resetView();
                }
            },
        
            userid: function(){
                if(this.userid > 0){
                    this.getMenuOptions();
                }
            },
            
            deselect_farm: function(){
                if(this.deselect_farm===true){
                    this.getMenuOptions();
                    this.resetView();
                    this.selected_available_farm = 0;
                    this.selected_in_progress_farm = 0;
                    this.selected_completed_farm = 0;
                    this.selected_approved_farm = 0;
                    this.selected_rejected_farm = 0;
                }
            },

            selected_available_farm: function(){
                if(this.selected_available_farm > 0){
                    this.selected_farm = this.selected_available_farm;
                    this.selected_in_progress_farm = 0;
                    this.selected_completed_farm = 0;
                    this.selected_approved_farm = 0;
                    this.selected_rejected_farm = 0;
                }else{
                    this.checkForSelectedFarm();
                }
            },

            selected_in_progress_farm: function(){
                if(this.selected_in_progress_farm > 0){
                    this.selected_farm = this.selected_in_progress_farm;
                    this.selected_available_farm = 0;
                    this.selected_completed_farm = 0;
                    this.selected_approved_farm = 0;
                    this.selected_rejected_farm = 0;
                }else{
                    this.checkForSelectedFarm();
                }
            },

            selected_completed_farm: function(){
                if(this.selected_completed_farm > 0){
                    this.selected_farm = this.selected_completed_farm;
                    this.selected_available_farm = 0;
                    this.selected_in_progress_farm = 0;
                    this.selected_approved_farm = 0;
                    this.selected_rejected_farm = 0;
                }else{
                    this.checkForSelectedFarm();
                }
            },

            selected_approved_farm: function(){
                if(this.selected_approved_farm > 0){
                    this.selected_farm = this.selected_approved_farm;
                    this.selected_available_farm = 0;
                    this.selected_in_progress_farm = 0;
                    this.selected_completed_farm = 0;
                    this.selected_rejected_farm = 0;
                }else{
                    this.checkForSelectedFarm();
                }
            },

            selected_rejected_farm: function(){
                if(this.selected_rejected_farm > 0){
                    this.selected_farm = this.selected_rejected_farm;
                    this.selected_available_farm = 0;
                    this.selected_in_progress_farm = 0;
                    this.selected_completed_farm = 0;
                    this.selected_approved_farm = 0;
                }else{
                    this.checkForSelectedFarm();
                }
            }
        },

        methods:{

            getMenuOptions(){
                const action = 'get_farm_menu';
                let url = '';
                url += 'https://'; 
                url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
                url += 'default/sadc_utilities?';
                url += `action=${action}&`;
                url += `userid=${this.userid}`;

                this.$emit('working', true);

                axios
                .post(url)
                .then(response => {
                    
                    if(response.data.success == 0){                                            
                        console.error( "Error in 'get_farm_menu'",
                                        response.data.message)
                    }else{
                        this.menuResponse = response.data.list;
                        this.parseMenuResponse();
                    }
                })
                .catch(error => {
                    console.error( "Error in 'get_farm_menu'", error)
                    return false;
                })
                .then(()=>{
                    this.$emit('working', false);
                });

            },

            parseMenuResponse(){
                let sort_array = [[],[],[],[],[]];
                this.menuResponse.forEach(item => 
                    {
                        switch (item.status) {
                            case 1:
                                sort_array[0].push(item)
                                break;
                            case 2:
                                sort_array[1].push(item)
                                break;
                            case 3:
                                sort_array[2].push(item)
                                break;
                            case 4:
                                sort_array[3].push(item)
                                break;
                            case 5:
                                sort_array[4].push(item)
                                break;
                            default:
                                console.error( "Error in farm menu values: '", 
                                JSON.stringify(item));
                                break;
                        }

                        this.available_farms = sort_array[0],
                        this.in_progress_farms = sort_array[1],
                        this.completed_farms = sort_array[2],
                        this.approved_farms = sort_array[3]
                        this.rejected_farms = sort_array[4]
                    }
                );

            },
            
            getFarmBoundary(){
                const action = 'get_farm_boundary';
                let url = '';
                url += 'https://'; 
                url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
                url += 'default/sadc_utilities?';
                url += `action=${action}&`;
                url += `idn_proper=${this.selected_farm}`;

                axios
                .post(url)
                .then(response => {
                    
                    if(Object.prototype.hasOwnProperty.call(
                                                response.data,'message')){                                            
                        console.error( "Error in 'get_farm_boundary'",
                                        response.data.message)
                        return false;
                    }else{
                        this.farm_boundary_geoJSON = response.data;
                        this.addFarmBoundary();
                        return true;
                    }
                })
                .catch(error => {
                    console.error( "Error in 'get_farm_boundary'", error)
                    return false;
                });
            },

            addFarmBoundary(){

                this.removeFarmBoundary();
                this.resetView();
                this.map.addSource('farm_boundary', {
                    type: 'geojson',
                    data: this.farm_boundary_geoJSON
                });

                this.map.addLayer({
                    'id': 'farm_boundary_outline',
                    'type': 'line',
                    'source': 'farm_boundary',
                    'layout': {},
                    'paint': {
                    'line-color': '#FF0000',
                    'line-width': 1
}
                });
 
                let bounds = bbox(this.farm_boundary_geoJSON);
                bounds = [[bounds[0],bounds[1]],[bounds[2],bounds[3]]];
                this.map.fitBounds(bounds);
            },

            removeFarmBoundary(){
                const sources = this.map.getStyle().sources;

                if(Object.prototype.hasOwnProperty.call(sources,
                                                            'farm_boundary')){
                    this.map.removeLayer('farm_boundary_outline');
                    this.map.removeSource('farm_boundary');
                }
                
            },

            checkForSelectedFarm(){
                if(this.selected_available_farm +
                   this.selected_in_progress_farm +
                   this.selected_completed_farm +
                   this.selected_approved_farm == 0){
                       this.selected_farm = 0;
                       this.resetView();
                }
            },

            resetView(){
                this.removeFarmBoundary();
                this.map.setCenter([this.default_view.longitude, 
                                    this.default_view.latitude]);
                this.map.setZoom(this.default_view.zoom);
                this.map.setBearing(this.default_view.bearing);
                this.map.setPitch(this.default_view.pitch);
            },

            select_farm(){
                let status = 0;
                
                if (this.selected_in_progress_farm > 0){
                    status = 2;
                }else if (this.selected_completed_farm > 0){
                    status = 3;
                }else if (this.selected_approved_farm > 0){
                    status = 4;
                }else{
                    status = 0;
                }

                this.$emit('selected_farm', {
                    'idn_proper': this.selected_farm,
                    'status': status
                });
            },

            toggleAdmin(){
                this.$emit('toggle_admin');
            }
        },

        mounted(){
        }

    }

</script>
