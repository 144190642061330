<template>

  <div class="w-72 h-auto absolute bg-gray-50 top-20 left-1/2 z-50 rounded shadow"
       id="classifier"
       draggable="true"
       v-show="is_open">
    <disturbance-codes
        :current_code="current_code"
        @code_selected="updateSelectedCode"
        @codes="emitCodes">
    </disturbance-codes>
        <div class="w-full m-0.5 flex place-content-center">
            <button class="bg-blue-500 text-white font-bold py-2 px-4 mx-1
                        rounded opacity-50 cursor-not-allowed"
                    v-show="reclassify_mode && (!selected_code || 
                            (selected_code==current_code && selected_code!=0))">
                Reclassify
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white mx-1
                        font-bold py-2 px-4 rounded opacity-100 
                        cursor-pointer"
                    id="farm-select-btn"
                    @click="reclassify"
                    v-show="reclassify_mode && selected_code!=current_code && 
                            selected_code!=0">
                Reclassify
            </button>
            <button class="bg-blue-500 text-white font-bold py-2 px-4 mx-1
                        rounded opacity-50 cursor-not-allowed"
                    v-show="!reclassify_mode && selected_code==0">
                Select
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white mx-1
                        font-bold py-2 px-4 rounded opacity-100 
                        cursor-pointer"
                    id="farm-select-btn"
                    @click="processSelectedCode"
                    v-show="!reclassify_mode && selected_code>0">
                Select
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white mx-1
                        font-bold py-2 px-4 rounded opacity-100 basis-1/4
                        cursor-pointer"
                    id="farm-select-btn"
                    v-show="show_edit"
                    @click="openEditMode">
                Edit
            </button>
            <button class="bg-blue-500 hover:bg-blue-700 text-white mx-1
                        font-bold py-2 px-4 rounded opacity-100 basis-1/4
                        cursor-pointer"
                    id="farm-select-btn"
                    v-show="current_code"
                    @click="deleteFeature">
                Delete
            </button>
        </div>
        <div class="w-full mx-0.5 my-1 flex place-content-center">
            <button class="bg-gray-500 text-white text-sm py-2 px-2 my-2
                        rounded opacity-100 basis-1/4"
                    @click="cancelClassification">
                Cancel
            </button>
        </div>
  </div>
  
</template>

<script>
    
    import DisturbanceCodes from './DisturbanceCodes.vue'
    import centroid from '@turf/centroid'

    export default {
        name: 'Classifier',

        components: {
            "disturbance-codes": DisturbanceCodes
        },

        emits:['selected_code', 'cancel_classification', 'reclassify_feature',
               'delete_feature', 'exit_reclassify_mode', 'enter_edit_mode',
               'codes'],

        props:['show_classifier', 'map', 'current_feature', 'reclassify_mode'],

        data(){
            return{
                selected_code: 0,
                current_code: 0,
                is_open: false,
                show_edit: true
            }
        },

        watch:{
            show_classifier: function(){
                if(this.show_classifier){
                    this.openClassifier();
                }else{
                    this.closeClassifier();
                }
            },

            current_feature: function(){
                
                if (!this.current_feature){
                    return;
                }

                if(Object.prototype.hasOwnProperty.call(
                                this.current_feature,'properties')){
                    if(Object.prototype.hasOwnProperty.call(
                        this.current_feature.properties,'featureid')){
                        
                        this.show_edit = true;
                    }else{
                        this.show_edit = false;
                    }
                }
            }
        },

        methods:{

            updateSelectedCode(code){
                this.selected_code = code;
            },

            processSelectedCode(){
                this.$emit('selected_code', this.selected_code);
            },

            cancelClassification(){
                this.closeClassifier();
                if(this.reclassify_mode){   
                    this.current_code = 0;
                    this.$emit('exit_reclassify_mode');
                }else{
                    this.$emit('cancel_classification');
                }
            },

            reclassify(){
                this.closeClassifier();
                this.$emit('reclassify_feature', 
                    {'code': this.selected_code,
                     'featureid': this.current_feature.properties.featureid
                    }
                )
            },

            deleteFeature(){
                this.closeClassifier();
                this.$emit('delete_feature',  this.current_feature);
            },

            openEditMode(){
                this.$emit("enter_edit_mode");
                this.updateSelectedCode(0);
                this.closeClassifier();

            },

            closeClassifier(){
                this.is_open = false;
            },

            openClassifier(){

                this.map.setPitch(0);

                const pitch = this.map.getPitch();
                const center = centroid(this.current_feature);
                const coordinates = center.geometry.coordinates;
                const point = this.map.project(coordinates);

                this.map.setPitch(pitch);

                let el = document.getElementById("map");
                const p_y = el.offsetTop + point.y;
                const p_x = el.offsetLeft + point.x;

                el = document.getElementById('classifier');
                el.style.top = p_y + "px";
                el.style.left = p_x + "px";

                if(Object.prototype.hasOwnProperty.call(
                                this.current_feature,'properties')){
                    if(Object.prototype.hasOwnProperty.call(
                        this.current_feature.properties,'code')){
                        this.current_code =  
                                this.current_feature.properties.code;
                    }
                }
                
                this.is_open = true;
            },

            emitCodes(codes){
                this.$emit('codes', codes);
            }

        }
    }
</script>
