<template>

  <div class="w-auto h-auto absolute bg-gray-50 top-20 left-1/2 z-50 
              text-gray-800 p-4 rounded shadow"
       id="feature-retriever"
       draggable="true"
       v-show="isOpen">
    <div class="w-full h-22 bg-white grid grid-cols-2">
        <div class="col-span-1 text-right pr-1 text-sm font-semibold">
            IDN Proper:
        </div>
        <div class="col-span-1 text-left pl-1 text-sm">
           {{this.idn_proper}}
        </div>
        <div class="col-span-1 text-right pr-1 text-sm font-semibold">
            Feature Source Table:
        </div>
        <div class="col-span-1 text-left pl-1 text-sm">
           {{this.source}}
        </div>
        <div class="col-span-1 text-right pr-1 text-sm font-semibold">
            Feature ID:
        </div>
        <div class="col-span-1 text-left pl-1 text-sm">
           {{this.feature_id}}
        </div>
        <div class="col-span-1 text-right pr-1 text-sm font-semibold">
            Class:
        </div>
        <div class="col-span-1 text-left pl-1 text-sm">
           {{this.codes[this.code]?this.codes[this.code].description:''}}
        </div>
        <div class="col-span-1 text-right pr-1 text-sm font-semibold">
            Approx. Area:
        </div>
        <div class="col-span-1 text-left pl-1 text-sm">
           {{area}} acs.
        </div>
    </div>
    <hr class="h-0.5 w-full bg-gray-300 my-1">
    <div class="w-full grid grid-cols-4">
        <div class="h-6 mt-0.5 mb-2 col-span-3 col-start-2">
            <input  type="checkbox"
                    id="use-entire-layer"
                    v-model="use_entire_layer"/>
            <label for="use-entire-layer"
                title="Replace all previously delineated features with the 
                        features in this layer">
                Use entire layer
            </label>
        </div>
    </div>
    <hr class="h-0.5 w-full bg-gray-300 my-1">
        <div class="w-full m-2 flex place-content-center">
            <button class="bg-blue-500 text-white font-bold py-2 px-4 
                        rounded"
                    id="add-features"
                    @click="addFeatures">
                Add
            </button>
            <button class="bg-blue-500 text-white font-bold py-2 px-4 mx-2 
                        rounded"
                    @click="cancelFeatureRetrieval">
                Cancel
            </button>
        </div>
  </div>

</template>

<script>
    
    import centroid from '@turf/centroid'
    import area from '@turf/area'
    import axios from 'axios';

    export default {
        name: 'FeatureRetriever',

        emits:['features_added'],

        props:['map', 'feature', 'userid', 'codes'],

        data(){
            return{
                idn_proper: null,
                feature_id: null,
                code: null,
                area: null,
                source: null,
                use_entire_layer: false,
                isOpen: false,
            }
        },

        watch:{
            feature: function(){
                this.parseFeature();
                this.openFeatureRetriever;
            },
        },

        methods:{

            addFeatures(){
                const action = 'retrieve_features';
                const feature_id = this.use_entire_layer?0:this.feature_id;
                    
                let url = '';
                url += 'https://'; 
                url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
                url += 'default/sadc_utilities?';
                url += `action=${action}&`;
                url += `idn_proper=${this.idn_proper}&`;  
                url += `userid=${this.userid}&`;     
                url += `source=${this.source}&`;
                url += `featureid=${feature_id}`;
                url = encodeURI(url);

                axios
                .get(url)
                .then(response => {
                    const data = response.data;

                    if(data.success == 0){  
                        // alert( "Error in 'add_features'", data.message);
                        console.log( "Error in 'add_features'", data.message);
                    }
                })
                .catch(error => {
                    console.log( "Error in 'add_features'", error);
                })
                .then(() => {
                    this.$emit('features_added');
                });
            },

            parseFeature(){
                if(!this.feature){
                    return;
                }
                this.source = this.feature.source;
                this.feature_id = this.feature.properties.id;
                this.idn_proper = this.feature.properties.idn_proper;
                this.code = this.feature.properties.code;
                this.area = (area(this.feature) * 10.7639/ 43560).toFixed(2) ;
            },

            cancelFeatureRetrieval(){
                this.closeFeatureRetriever();
                this.feature_id = null;
                this.source = null;
                this.code = null;
                this.isOpen = false;
                this.$emit('features_added');
            },

            closeFeatureRetriever(){
                this.isOpen = false;
            },

            openFeatureRetriever(){

                this.map.setPitch(0);

                const pitch = this.map.getPitch();
                const center = centroid(this.feature);
                const coordinates = center.geometry.coordinates;
                const point = this.map.project(coordinates);

                this.map.setPitch(pitch);

                let el = document.getElementById("map");
                const p_y = el.offsetTop + point.y;
                const p_x = el.offsetLeft + point.x;

                el = document.getElementById('feature-retriever');
                el.style.top = p_y + "px";
                el.style.left = p_x + "px";

                if(Object.prototype.hasOwnProperty.call(
                                this.current_feature,'properties')){
                    if(Object.prototype.hasOwnProperty.call(
                        this.current_feature.properties,'code')){
                        this.current_code =  
                                this.current_feature.properties.code;
                    }
                }
                
                this.isOpen = true;
            },

        }
    }
</script>
