<template>
<div class="w-full h-auto m-2">

  <div class="text-center mt-8 font-semibold text-base text-blue-600">
    Estimated Completion Date for All Farms: 
    <span v-if="table.end" class="text-red-700">{{table.end.end}}</span>
  </div>
  <div class="text-left p-4 font-semibold text-base text-blue-600">
    Summary
  </div>
  <div class="flex w-3/4 font-normal text-sm ml-10 mb-2 border-b border-solid">
    <div class="flex-none w-1/12">USER</div>
    <div class="flex-none text-right w-1/12">FARMS</div>
    <div class="flex-none text-right w-3/12">AVG % COMPLETE</div>
    <div class="flex-none text-right w-2/12">COMPLETED</div>
    <div class="flex-none text-right w-2/12">APPROVED</div>
    <div class="flex-none text-center whitespace-nowrap w-3/12">LAST ACTIVE</div>
  </div>
  <div class="flex w-3/4 font-normal text-sm ml-10"
       v-for="item in table.summary"
       :key="item.userid">
    <div class="flex-none w-1/12">{{item.last_name}}</div>
    <div class="flex-none text-right w-1/12">{{item.delineations}}</div>
    <div class="flex-none text-right w-2/12">{{item.avg_completion}}</div>
    <div class="flex-none text-right w-2/12">{{item.completed}}</div>
    <div class="flex-none text-right w-2/12">{{item.approved}}</div>
    <div class="flex-none text-right w-4/12">{{item.last_active}}</div>
  </div>
  <div class="flex w-3/4 font-normal text-sm ml-10 border-t border-solid mt-1">
    <div class="flex-none w-1/12 pt-1">Totals:</div>
    <div class="flex-none text-right w-1/12 pt-1">{{totals.farms}}</div>
    <div class="flex-none text-right w-2/12"></div>
    <div class="flex-none text-right w-2/12 pt-1">{{totals.completed}}</div>
    <div class="flex-none text-right w-2/12 pt-1">{{totals.approved}}</div>
    <div class="flex-none text-right w-4/12"></div>
  </div>
</div>
  <table-lite 
    :is-loading="table.isLoading"
    :columns="table.columns"
    :rows="table.rows"
    :total="table.totalRecordCount"
    :sortable="table.sortable"
    :messages="table.messages"
    @do-search="doSearch"
    @is-finished="table.isLoading = false"
    class="mx-1"
  ></table-lite>
</template>

<script>
import { defineComponent, reactive } from "vue";
import TableLite from 'vue3-table-lite'
import axios from 'axios';

export default defineComponent({

    components: { TableLite },

    computed: {
      summary(){
        return this.table.summary
      }
    },

    watch:{
      summary: function(){
        this.getTotals();
      }
     
    },

    setup() {
      const table = reactive({
        isLoading: false,
        columns: [
          {
            label: "Name",
            field: "last_name",
            width: "10%",
            sortable: true,
          },
          {
            label: "IDN_PROPER",
            field: "idn_proper",
            width: "5%",
            sortable: true,
            isKey: true,
          },
          {
            label: "Reviewed",
            field: "reviewed",
            width: "5%",
            sortable: true,
          },
          {
            label: "Reviewer",
            field: "reviewer",
            width: "10%",
            sortable: true,
          },
          {
            label: "Notes",
            field: "reviewer_notes",
            width: "25%",
            sortable: true,
          },
          {
            label: "Approved",
            field: "approved",
            width: "5%",
            sortable: true,
          },
          {
            label: "Features",
            field: "features",
            width: "3%",
            sortable: true,
          },
        ],
        rows: [],
        totalRecordCount: 0,
        sortable: {
          order: "userid",
          sort: "asc",
        },
        summary: {}
      });
  
      const doSearch = (offset, limit, order, sort) => {
        table.isLoading = true;
  
        const action = 'get_admin_info';
        let url = '';
        url += 'https://'; 
        url += '8to08gnyej.execute-api.us-east-1.amazonaws.com/';
        url += 'default/sadc_utilities?';
        url += `action=${action}&`;
        url += `offset=${offset}&`;
        url += `limit=${limit}&`;
        url += `order=${order}&`;
        url += `sort=${sort}`;
        axios.get(url)
        .then((response) => {
  
          table.rows = response.data.data.details;
          table.totalRecordCount = response.data.data.details[0].full_count;
          table.sortable.order = order;
          table.sortable.sort = sort;
          table.summary = response.data.data.summary;
          table.end = response.data.data.end;
        });
      };
  
      const tableLoadingFinish = () => {
        table.isLoading = false;
      };

      let totals = {'farms': 0,
                    'completed': 0,
                    'approved': 0
                   };

      const  getTotals = () => {
        for (const item in table.summary){
            totals.farms += table.summary[item].delineations;
            totals.completed += table.summary[item].completed;
            totals.approved += table.summary[item].approved;
        }

      }

      doSearch(0, 10, 'last_name', 'asc');
  
      return {
        table,
        doSearch,
        tableLoadingFinish,
        totals,
        getTotals
      };
  },

  mounted(){
    // this.getTotals();
  }

});
</script>


<style scoped>
::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #fff;
  background-color: rgba(29, 78, 216, .8);
  border-color: #fff;
}
::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
  border: none;
}
::v-deep(.vtl-paging-info) {
  color: rgb(29, 78, 216);
}
::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
  color: rgb(29, 78, 216);
  margin: .5em;
}
::v-deep(.vtl-paging-pagination-page-link) {
  border: none;
}
</style>